import { SENSITIVE_STRING } from "@smithy/smithy-client";
import { LocationServiceException as __BaseException } from "./LocationServiceException";
export class AccessDeniedException extends __BaseException {
    constructor(opts) {
        super({
            name: "AccessDeniedException",
            $fault: "client",
            ...opts,
        });
        this.name = "AccessDeniedException";
        this.$fault = "client";
        Object.setPrototypeOf(this, AccessDeniedException.prototype);
        this.Message = opts.Message;
    }
}
export class ConflictException extends __BaseException {
    constructor(opts) {
        super({
            name: "ConflictException",
            $fault: "client",
            ...opts,
        });
        this.name = "ConflictException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ConflictException.prototype);
        this.Message = opts.Message;
    }
}
export class InternalServerException extends __BaseException {
    constructor(opts) {
        super({
            name: "InternalServerException",
            $fault: "server",
            ...opts,
        });
        this.name = "InternalServerException";
        this.$fault = "server";
        this.$retryable = {};
        Object.setPrototypeOf(this, InternalServerException.prototype);
        this.Message = opts.Message;
    }
}
export class ServiceQuotaExceededException extends __BaseException {
    constructor(opts) {
        super({
            name: "ServiceQuotaExceededException",
            $fault: "client",
            ...opts,
        });
        this.name = "ServiceQuotaExceededException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ServiceQuotaExceededException.prototype);
        this.Message = opts.Message;
    }
}
export class ThrottlingException extends __BaseException {
    constructor(opts) {
        super({
            name: "ThrottlingException",
            $fault: "client",
            ...opts,
        });
        this.name = "ThrottlingException";
        this.$fault = "client";
        this.$retryable = {};
        Object.setPrototypeOf(this, ThrottlingException.prototype);
        this.Message = opts.Message;
    }
}
export class ValidationException extends __BaseException {
    constructor(opts) {
        super({
            name: "ValidationException",
            $fault: "client",
            ...opts,
        });
        this.name = "ValidationException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ValidationException.prototype);
        this.Message = opts.Message;
        this.Reason = opts.Reason;
        this.FieldList = opts.FieldList;
    }
}
export class ResourceNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "ResourceNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "ResourceNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ResourceNotFoundException.prototype);
        this.Message = opts.Message;
    }
}
export const CreateKeyResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const DescribeKeyResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const DevicePositionUpdateFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Position && { Position: SENSITIVE_STRING }),
    ...(obj.PositionProperties && { PositionProperties: SENSITIVE_STRING }),
});
export const BatchEvaluateGeofencesRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.DevicePositionUpdates && {
        DevicePositionUpdates: obj.DevicePositionUpdates.map((item) => DevicePositionUpdateFilterSensitiveLog(item)),
    }),
});
export const DevicePositionFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Position && { Position: SENSITIVE_STRING }),
    ...(obj.PositionProperties && { PositionProperties: SENSITIVE_STRING }),
});
export const BatchGetDevicePositionResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.DevicePositions && {
        DevicePositions: obj.DevicePositions.map((item) => DevicePositionFilterSensitiveLog(item)),
    }),
});
export const CircleFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Center && { Center: SENSITIVE_STRING }),
});
export const GeofenceGeometryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Polygon && { Polygon: obj.Polygon.map((item) => SENSITIVE_STRING) }),
    ...(obj.Circle && { Circle: SENSITIVE_STRING }),
    ...(obj.Geobuf && { Geobuf: SENSITIVE_STRING }),
});
export const BatchPutGeofenceRequestEntryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Geometry && { Geometry: GeofenceGeometryFilterSensitiveLog(obj.Geometry) }),
    ...(obj.GeofenceProperties && { GeofenceProperties: SENSITIVE_STRING }),
});
export const BatchPutGeofenceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Entries && { Entries: obj.Entries.map((item) => BatchPutGeofenceRequestEntryFilterSensitiveLog(item)) }),
});
export const BatchUpdateDevicePositionRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Updates && { Updates: obj.Updates.map((item) => DevicePositionUpdateFilterSensitiveLog(item)) }),
});
export const CalculateRouteRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.DeparturePosition && { DeparturePosition: SENSITIVE_STRING }),
    ...(obj.DestinationPosition && { DestinationPosition: SENSITIVE_STRING }),
    ...(obj.WaypointPositions && { WaypointPositions: SENSITIVE_STRING }),
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const LegGeometryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.LineString && { LineString: SENSITIVE_STRING }),
});
export const StepFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.StartPosition && { StartPosition: SENSITIVE_STRING }),
    ...(obj.EndPosition && { EndPosition: SENSITIVE_STRING }),
});
export const LegFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.StartPosition && { StartPosition: SENSITIVE_STRING }),
    ...(obj.EndPosition && { EndPosition: SENSITIVE_STRING }),
    ...(obj.Geometry && { Geometry: LegGeometryFilterSensitiveLog(obj.Geometry) }),
    ...(obj.Steps && { Steps: obj.Steps.map((item) => StepFilterSensitiveLog(item)) }),
});
export const CalculateRouteSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.RouteBBox && { RouteBBox: SENSITIVE_STRING }),
});
export const CalculateRouteResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Legs && { Legs: obj.Legs.map((item) => LegFilterSensitiveLog(item)) }),
    ...(obj.Summary && { Summary: CalculateRouteSummaryFilterSensitiveLog(obj.Summary) }),
});
export const CalculateRouteMatrixRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.DeparturePositions && { DeparturePositions: SENSITIVE_STRING }),
    ...(obj.DestinationPositions && { DestinationPositions: SENSITIVE_STRING }),
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const CalculateRouteMatrixResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.SnappedDeparturePositions && { SnappedDeparturePositions: SENSITIVE_STRING }),
    ...(obj.SnappedDestinationPositions && { SnappedDestinationPositions: SENSITIVE_STRING }),
});
export const DeviceStateFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Position && { Position: SENSITIVE_STRING }),
});
export const ForecastedEventFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.GeofenceProperties && { GeofenceProperties: SENSITIVE_STRING }),
});
export const ForecastGeofenceEventsDeviceStateFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Position && { Position: SENSITIVE_STRING }),
});
export const ForecastGeofenceEventsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.DeviceState && { DeviceState: ForecastGeofenceEventsDeviceStateFilterSensitiveLog(obj.DeviceState) }),
});
export const ForecastGeofenceEventsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.ForecastedEvents && {
        ForecastedEvents: obj.ForecastedEvents.map((item) => ForecastedEventFilterSensitiveLog(item)),
    }),
});
export const GetGeofenceResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Geometry && { Geometry: GeofenceGeometryFilterSensitiveLog(obj.Geometry) }),
    ...(obj.GeofenceProperties && { GeofenceProperties: SENSITIVE_STRING }),
});
export const ListGeofenceResponseEntryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Geometry && { Geometry: GeofenceGeometryFilterSensitiveLog(obj.Geometry) }),
    ...(obj.GeofenceProperties && { GeofenceProperties: SENSITIVE_STRING }),
});
export const ListGeofencesResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Entries && { Entries: obj.Entries.map((item) => ListGeofenceResponseEntryFilterSensitiveLog(item)) }),
});
export const PutGeofenceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Geometry && { Geometry: GeofenceGeometryFilterSensitiveLog(obj.Geometry) }),
    ...(obj.GeofenceProperties && { GeofenceProperties: SENSITIVE_STRING }),
});
export const GetDevicePositionResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Position && { Position: SENSITIVE_STRING }),
    ...(obj.PositionProperties && { PositionProperties: SENSITIVE_STRING }),
});
export const GetDevicePositionHistoryResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.DevicePositions && {
        DevicePositions: obj.DevicePositions.map((item) => DevicePositionFilterSensitiveLog(item)),
    }),
});
export const GetMapGlyphsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const GetMapSpritesRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const GetMapStyleDescriptorRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const GetMapTileRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const GetPlaceRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const PlaceGeometryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Point && { Point: SENSITIVE_STRING }),
});
export const PlaceFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Geometry && { Geometry: PlaceGeometryFilterSensitiveLog(obj.Geometry) }),
});
export const GetPlaceResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Place && { Place: PlaceFilterSensitiveLog(obj.Place) }),
});
export const InferredStateFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Position && { Position: SENSITIVE_STRING }),
});
export const TrackingFilterGeometryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Polygon && { Polygon: obj.Polygon.map((item) => SENSITIVE_STRING) }),
});
export const ListDevicePositionsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.FilterGeometry && { FilterGeometry: TrackingFilterGeometryFilterSensitiveLog(obj.FilterGeometry) }),
});
export const ListDevicePositionsResponseEntryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Position && { Position: SENSITIVE_STRING }),
    ...(obj.PositionProperties && { PositionProperties: SENSITIVE_STRING }),
});
export const ListDevicePositionsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Entries && { Entries: obj.Entries.map((item) => ListDevicePositionsResponseEntryFilterSensitiveLog(item)) }),
});
export const SearchPlaceIndexForPositionRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Position && { Position: SENSITIVE_STRING }),
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const SearchForPositionResultFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Place && { Place: PlaceFilterSensitiveLog(obj.Place) }),
});
export const SearchPlaceIndexForPositionSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Position && { Position: SENSITIVE_STRING }),
});
export const SearchPlaceIndexForPositionResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Summary && { Summary: SearchPlaceIndexForPositionSummaryFilterSensitiveLog(obj.Summary) }),
    ...(obj.Results && { Results: obj.Results.map((item) => SearchForPositionResultFilterSensitiveLog(item)) }),
});
export const SearchPlaceIndexForSuggestionsRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Text && { Text: SENSITIVE_STRING }),
    ...(obj.BiasPosition && { BiasPosition: SENSITIVE_STRING }),
    ...(obj.FilterBBox && { FilterBBox: SENSITIVE_STRING }),
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const SearchPlaceIndexForSuggestionsSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Text && { Text: SENSITIVE_STRING }),
    ...(obj.BiasPosition && { BiasPosition: SENSITIVE_STRING }),
    ...(obj.FilterBBox && { FilterBBox: SENSITIVE_STRING }),
});
export const SearchPlaceIndexForSuggestionsResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Summary && { Summary: SearchPlaceIndexForSuggestionsSummaryFilterSensitiveLog(obj.Summary) }),
});
export const SearchPlaceIndexForTextRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Text && { Text: SENSITIVE_STRING }),
    ...(obj.BiasPosition && { BiasPosition: SENSITIVE_STRING }),
    ...(obj.FilterBBox && { FilterBBox: SENSITIVE_STRING }),
    ...(obj.Key && { Key: SENSITIVE_STRING }),
});
export const SearchForTextResultFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Place && { Place: PlaceFilterSensitiveLog(obj.Place) }),
});
export const SearchPlaceIndexForTextSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Text && { Text: SENSITIVE_STRING }),
    ...(obj.BiasPosition && { BiasPosition: SENSITIVE_STRING }),
    ...(obj.FilterBBox && { FilterBBox: SENSITIVE_STRING }),
    ...(obj.ResultBBox && { ResultBBox: SENSITIVE_STRING }),
});
export const SearchPlaceIndexForTextResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.Summary && { Summary: SearchPlaceIndexForTextSummaryFilterSensitiveLog(obj.Summary) }),
    ...(obj.Results && { Results: obj.Results.map((item) => SearchForTextResultFilterSensitiveLog(item)) }),
});
export const VerifyDevicePositionRequestFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.DeviceState && { DeviceState: DeviceStateFilterSensitiveLog(obj.DeviceState) }),
});
export const VerifyDevicePositionResponseFilterSensitiveLog = (obj) => ({
    ...obj,
    ...(obj.InferredState && { InferredState: InferredStateFilterSensitiveLog(obj.InferredState) }),
});
